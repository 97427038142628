import {
    RESET_THIRD_STEP_STATE,
    SET_COTTAGE_EQUITY, SET_HOME_EQUITY,
    SET_INHERITANCE_AMOUNT_SPOUSE,
    SET_INHERITANCE_AMOUNT_YOU,
    SET_INHERITANCE_SPOUSE, SET_INHERITANCE_YEAR_SPOUSE, SET_INHERITANCE_YEAR_YOU,
    SET_INHERITANCE_YOU,
    SET_LOCKED_IN_LEGISLATION_TYPE_SPOUSE,
    SET_LOCKED_IN_LEGISLATION_TYPE_YOU,
    SET_LOCKED_IN_TOTAL_SPOUSE,
    SET_LOCKED_IN_TOTAL_YOU,
    SET_NON_REGISTERED_CONTRIBUTION_SPOUSE,
    SET_NON_REGISTERED_CONTRIBUTION_YOU,
    SET_NON_REGISTERED_TOTAL_SPOUSE,
    SET_NON_REGISTERED_TOTAL_YOU,
    SET_PROPERTIES,
    SET_RENTAL_EQUITY,
    SET_RRSP_CONTRIBUTE_SPOUSE,
    SET_RRSP_CONTRIBUTE_YOU,
    SET_RRSP_TOTAL_SPOUSE,
    SET_RRSP_TOTAL_YOU,
    SET_SAVINGS_OR_INVESTMENTS_SELECTOR_SPOUSE,
    SET_SAVINGS_OR_INVESTMENTS_SELECTOR_YOU,
    SET_TFSA_CONTRIBUTION_SPOUSE,
    SET_TFSA_CONTRIBUTION_YOU,
    SET_TFSA_TOTAL_SPOUSE,
    SET_TFSA_TOTAL_YOU, SET_THIRD_STEP_COMPLETED,
    SET_WORKPLACE_SAVINGS_CONTRIBUTION_SPOUSE,
    SET_WORKPLACE_SAVINGS_CONTRIBUTION_YOU,
    SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_SPOUSE,
    SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_YOU,
    SET_WORKPLACE_SAVINGS_TOTAL_SPOUSE,
    SET_WORKPLACE_SAVINGS_TOTAL_YOU
} from "./actionTypes";

import savingsAndInvestmentsInitState from "../initStates/savingsAndInvestmentsInitState";
import { fetchRadioError } from "./aboutYou";

let localStorageState = savingsAndInvestmentsInitState;

function setLocalStorageData(params) {

    const savingsAndInvestments = localStorage.getItem('savingsAndInvestments');

    localStorageState = savingsAndInvestments ? JSON.parse(savingsAndInvestments) : localStorageState;

    switch (params.type ) {
        case 'resetThirdStep':
            localStorageState = savingsAndInvestmentsInitState;
            break;
        case 'setRrspTotalYou':
            localStorageState.you.rrsp.total = params.val;
            break;
        case 'setRrspTotalSpouse':
            localStorageState.spouse.rrsp.total = params.val;
            break;
        case 'setRrspContributeYou':
            localStorageState.you.rrsp.contributeMonthly = params.val;
            break;
        case 'setRrspContributeSpouse':
            localStorageState.spouse.rrsp.contributeMonthly = params.val;
            break;
        case 'setWorkplaceSavingsTotalYou':
            localStorageState.you.workplaceSavings.total = params.val;
            break;
        case 'setWorkplaceSavingsTotalSpouse':
            localStorageState.spouse.workplaceSavings.total = params.val;
            break;
        case 'setWorkplaceSavingsContributeYou':
            localStorageState.you.workplaceSavings.contributeMonthly = params.val;
            break;
        case 'setWorkplaceSavingsContributeSpouse':
            localStorageState.spouse.workplaceSavings.contributeMonthly = params.val;
            break;
        case 'setWorkplaceSavingsLegislationTypeYou':
            localStorageState.you.workplaceSavings.legislationType = params.val;
            break;
        case 'setWorkplaceSavingsLegislationTypeSpouse':
            localStorageState.spouse.workplaceSavings.legislationType = params.val;
            break;
        case 'setLockedInTotalYou':
            localStorageState.you.lockedIn.total = params.val;
            break;
        case 'setLockedInTotalSpouse':
            localStorageState.spouse.lockedIn.total = params.val;
            break;
        case 'setLockedInLegislationTypeYou':
            localStorageState.you.lockedIn.legislationType = params.val;
            break;
        case 'setLockedInLegislationTypeSpouse':
            localStorageState.spouse.lockedIn.legislationType = params.val;
            break;
        case 'setTfsaTotalYou':
            localStorageState.you.tfsa.total = params.val;
            break;
        case 'setTfsaTotalSpouse':
            localStorageState.spouse.tfsa.total = params.val;
            break;
        case 'setTfsaContributeYou':
            localStorageState.you.tfsa.contributeMonthly = params.val;
            break;
        case 'setTfsaContributeSpouse':
            localStorageState.spouse.tfsa.contributeMonthly = params.val;
            break;
        case 'setNonRegisteredTotalYou':
            localStorageState.you.nonRegistered.total = params.val;
            break;
        case 'setNonRegisteredTotalSpouse':
            localStorageState.spouse.nonRegistered.total = params.val;
            break;
        case 'setNonRegisteredContributeYou':
            localStorageState.you.nonRegistered.contributeMonthly = params.val;
            break;
        case 'setNonRegisteredContributeSpouse':
            localStorageState.spouse.nonRegistered.contributeMonthly = params.val;
            break;
        case 'setCottageEquity':
            localStorageState.properties.cottages = params.val;
            break;
        case 'setRentalEquity':
            localStorageState.properties.rentalProperties = params.val;
            break;
        case 'setHomeEquity':
            localStorageState.properties.home = params.val;
            break;
        case 'setInheritanceYou':
            localStorageState.you.inheritance.exist = params.val === 'Yes';
            if(params.val === 'No'){
                localStorageState.you.inheritance.total = 0;
            }
            break;
        case 'setInheritanceSpouse':
            localStorageState.spouse.inheritance.exist = params.val === 'Yes';
            if(params.val === 'No'){
                localStorageState.spouse.inheritance.total = 0;
            }
            break;
        case 'setInheritanceAmountYou':
            localStorageState.you.inheritance.total = params.val;
            break;
        case 'setInheritanceAmountSpouse':
            localStorageState.spouse.inheritance.total = params.val;
            break;
        case 'setInheritanceYearYou':
            localStorageState.you.inheritance.inheritanceYear = params.val;
            break;
        case 'setInheritanceYearSpouse':
            localStorageState.spouse.inheritance.inheritanceYear = params.val;
            break;
        case 'thirdStepCompleted':
            localStorageState.thirdStepCompleted = params.val
            break;
        default:
            return true
    }

    localStorage.setItem('savingsAndInvestments', JSON.stringify(localStorageState));
}

export function resetThirdStep() {
    setLocalStorageData({
                            type: 'resetThirdStep',
                        })
    return {
        type: RESET_THIRD_STEP_STATE
    }
}

export function updateSavingsOrInvestmentsData(checkedItems) {
    return async dispatch => {
        try {
            const savingsAndInvestments = localStorage.getItem('savingsAndInvestments')

            const data = {};
            if(savingsAndInvestments) {
                localStorageState = JSON.parse(savingsAndInvestments);

                checkedItems.forEach((checked, name)=>{
                    if (name === 'rrspYou') {
                        data.rrspYou = checked;
                        localStorageState.you.rrsp.exist = checked;
                        if(!checked) {
                            localStorageState.you.rrsp.total = 0;
                            localStorageState.you.rrsp.contributeMonthly = 0
                        }
                    }
                    if (name === 'workplaceSavingsYou') {
                        data.workplaceSavingsYou = checked;
                        localStorageState.you.workplaceSavings.exist = checked;
                        if(!checked) {
                            localStorageState.you.workplaceSavings.total = 0;
                            localStorageState.you.workplaceSavings.contributeMonthly = 0;
                            localStorageState.you.workplaceSavings.legislationType = null
                        }
                    }
                    if (name === 'lockedInYou') {
                        data.lockedInYou = checked;
                        localStorageState.you.lockedIn.exist = checked;
                        if(!checked) {
                            localStorageState.you.lockedIn.total = 0;
                            localStorageState.you.lockedIn.legislationType = 0
                        }
                    }
                    if (name === 'tfsaYou') {
                        data.tfsaYou = checked;
                        localStorageState.you.tfsa.exist = checked;
                        if(!checked) {
                            localStorageState.you.tfsa.total = 0;
                            localStorageState.you.tfsa.contributeMonthly = 0
                        }
                    }
                    if (name === 'nonRegisteredYou') {
                        data.nonRegisteredYou = checked;
                        localStorageState.you.nonRegistered.exist = checked;
                        if(!checked) {
                            localStorageState.you.nonRegistered.total = 0;
                            localStorageState.you.nonRegistered.contributeMonthly = 0
                        }
                    }
                })

            }
            localStorage.setItem('savingsAndInvestments', JSON.stringify(localStorageState));

            dispatch(updateSavingsOrInvestmentsSelector(data))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }
}

export function updateSavingsOrInvestmentsSelector(data) {
    return {
        type: SET_SAVINGS_OR_INVESTMENTS_SELECTOR_YOU,
        data
    }
}

export function updateSavingsOrInvestmentsSpouseData(checkedItems) {
    return async dispatch => {
        try {
            const savingsAndInvestments = localStorage.getItem('savingsAndInvestments');

            const data = {};
            if(savingsAndInvestments) {
                localStorageState = JSON.parse(savingsAndInvestments);

                checkedItems.forEach((checked, name)=>{
                    if (name === 'rrspSpouse') {
                        data.rrspYou = checked;
                        localStorageState.spouse.rrsp.exist = checked;
                        if (!checked) {
                            localStorageState.spouse.rrsp.total = 0;
                            localStorageState.spouse.rrsp.contributeMonthly = 0;
                        }
                    }
                    if (name === 'workplaceSavingsSpouse') {
                        data.workplaceSavingsYou = checked;
                        localStorageState.spouse.workplaceSavings.exist = checked;
                        if (!checked) {
                            localStorageState.spouse.workplaceSavings.total = 0;
                            localStorageState.spouse.workplaceSavings.contributeMonthly = 0;
                            localStorageState.spouse.workplaceSavings.legislationType = null;
                        }
                    }
                    if (name === 'lockedInSpouse') {
                        data.lockedInYou = checked;
                        localStorageState.spouse.lockedIn.exist = checked;
                        if (!checked) {
                            localStorageState.spouse.lockedIn.total = 0;
                            localStorageState.spouse.lockedIn.legislationType = null;
                        }
                    }
                    if (name === 'tfsaSpouse') {
                        data.tfsaYou = checked;
                        localStorageState.spouse.tfsa.exist = checked;
                        if (!checked) {
                            localStorageState.spouse.tfsa.total = 0;
                            localStorageState.spouse.tfsa.contributeMonthly = 0;
                        }
                    }
                    if (name === 'nonRegisteredSpouse') {
                        data.nonRegisteredYou = checked;
                        localStorageState.spouse.nonRegistered.exist = checked;
                        if (!checked) {
                            localStorageState.spouse.nonRegistered.total = 0;
                            localStorageState.spouse.nonRegistered.contributeMonthly = 0;
                        }
                    }
                })

            }
            localStorage.setItem('savingsAndInvestments', JSON.stringify(localStorageState));

            dispatch(updateSavingsOrInvestmentsSelectorSpouse(data))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }

}

export function updateSavingsOrInvestmentsSelectorSpouse(data) {
    return {
        type: SET_SAVINGS_OR_INVESTMENTS_SELECTOR_SPOUSE,
        data
    }
}

export function setRrspTotalYou(val) {
    setLocalStorageData({
                            type: 'setRrspTotalYou',
                            val
                        })
    return {
        type: SET_RRSP_TOTAL_YOU,
        val
    }
}

export function setRrspTotalSpouse(val) {
    setLocalStorageData({
                            type: 'setRrspTotalSpouse',
                            val
                        })
    return {
        type: SET_RRSP_TOTAL_SPOUSE,
        val
    }
}

export function setRrspContributeYou(val) {
    setLocalStorageData({
                            type: 'setRrspContributeYou',
                            val
                        })
    return {
        type: SET_RRSP_CONTRIBUTE_YOU,
        val
    }
}

export function setRrspContributeSpouse(val) {
    setLocalStorageData({
                            type: 'setRrspContributeSpouse',
                            val
                        })
    return {
        type: SET_RRSP_CONTRIBUTE_SPOUSE,
        val
    }
}

export function setWorkplaceSavingsTotalYou(val) {
    setLocalStorageData({
                            type: 'setWorkplaceSavingsTotalYou',
                            val
                        })
    return {
        type: SET_WORKPLACE_SAVINGS_TOTAL_YOU,
        val
    }
}

export function setWorkplaceSavingsTotalSpouse(val) {
    setLocalStorageData({
                            type: 'setWorkplaceSavingsTotalSpouse',
                            val
                        })
    return {
        type: SET_WORKPLACE_SAVINGS_TOTAL_SPOUSE,
        val
    }
}

export function setWorkplaceSavingsContributeYou(val) {
    setLocalStorageData({
                            type: 'setWorkplaceSavingsContributeYou',
                            val
                        })
    return {
        type: SET_WORKPLACE_SAVINGS_CONTRIBUTION_YOU,
        val
    }
}

export function setWorkplaceSavingsContributeSpouse(val) {
    setLocalStorageData({
                            type: 'setWorkplaceSavingsContributeSpouse',
                            val
                        })
    return {
        type: SET_WORKPLACE_SAVINGS_CONTRIBUTION_SPOUSE,
        val
    }
}

export function setWorkplaceSavingsLegislationTypeYou(val) {
    setLocalStorageData({
                            type: 'setWorkplaceSavingsLegislationTypeYou',
                            val
                        })
    return {
        type: SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_YOU,
        val
    }
}

export function setWorkplaceSavingsLegislationTypeSpouse(val) {
    setLocalStorageData({
                            type: 'setWorkplaceSavingsLegislationTypeSpouse',
                            val
                        })
    return {
        type: SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_SPOUSE,
        val
    }
}

export function setLockedInTotalYou(val) {
    setLocalStorageData({
                            type: 'setLockedInTotalYou',
                            val
                        })
    return {
        type: SET_LOCKED_IN_TOTAL_YOU,
        val
    }
}

export function setLockedInTotalSpouse(val) {
    setLocalStorageData({
                            type: 'setLockedInTotalSpouse',
                            val
                        })
    return {
        type: SET_LOCKED_IN_TOTAL_SPOUSE,
        val
    }
}

export function setLockedInLegislationTypeYou(val) {
    setLocalStorageData({
                            type: 'setLockedInLegislationTypeYou',
                            val
                        })
    return {
        type: SET_LOCKED_IN_LEGISLATION_TYPE_YOU,
        val
    }
}

export function setLockedInLegislationTypeSpouse(val) {
    setLocalStorageData({
                            type: 'setLockedInLegislationTypeSpouse',
                            val
                        })
    return {
        type: SET_LOCKED_IN_LEGISLATION_TYPE_SPOUSE,
        val
    }
}

export function setTfsaTotalYou(val) {
    setLocalStorageData({
                            type: 'setTfsaTotalYou',
                            val
                        })
    return {
        type: SET_TFSA_TOTAL_YOU,
        val
    }
}

export function setTfsaTotalSpouse(val) {
    setLocalStorageData({
                            type: 'setTfsaTotalSpouse',
                            val
                        })
    return {
        type: SET_TFSA_TOTAL_SPOUSE,
        val
    }
}

export function setTfsaContributeYou(val) {
    setLocalStorageData({
                            type: 'setTfsaContributeYou',
                            val
                        })
    return {
        type: SET_TFSA_CONTRIBUTION_YOU,
        val
    }
}

export function setTfsaContributeSpouse(val) {
    setLocalStorageData({
                            type: 'setTfsaContributeSpouse',
                            val
                        })
    return {
        type: SET_TFSA_CONTRIBUTION_SPOUSE,
        val
    }
}

export function setNonRegisteredTotalYou(val) {
    setLocalStorageData({
                            type: 'setNonRegisteredTotalYou',
                            val
                        })
    return {
        type: SET_NON_REGISTERED_TOTAL_YOU,
        val
    }
}

export function setNonRegisteredTotalSpouse(val) {
    setLocalStorageData({
                            type: 'setNonRegisteredTotalSpouse',
                            val
                        })
    return {
        type: SET_NON_REGISTERED_TOTAL_SPOUSE,
        val
    }
}

export function setNonRegisteredContributeYou(val) {
    setLocalStorageData({
                            type: 'setNonRegisteredContributeYou',
                            val
                        })
    return {
        type: SET_NON_REGISTERED_CONTRIBUTION_YOU,
        val
    }
}

export function setNonRegisteredContributeSpouse(val) {
    setLocalStorageData({
                            type: 'setNonRegisteredContributeSpouse',
                            val
                        })
    return {
        type: SET_NON_REGISTERED_CONTRIBUTION_SPOUSE,
        val
    }
}

export function setProperties(checkedItems) {
    return async dispatch => {
        try {
            const savingsAndInvestments = localStorage.getItem('savingsAndInvestments');

            const data = {};
            if(savingsAndInvestments) {
                localStorageState = JSON.parse(savingsAndInvestments);

                checkedItems.forEach((checked, name)=>{
                    if (name === 'cottage') {
                        data.cottage = checked;
                        localStorageState.properties.exist = checked;
                        localStorageState.properties.isCottage = checked
                    }
                    if (name === 'rental') {
                        data.rental = checked;
                        localStorageState.properties.exist = checked;
                        localStorageState.properties.isRentalProperties = checked
                    }
                    if (name === 'no') {
                        data.no = checked;
                        if(checked) {
                            localStorageState.properties.exist = false;
                            localStorageState.properties.isCottage = false;
                            localStorageState.properties.isRentalProperties = false
                        }
                    }
                    localStorageState.properties.exist = localStorageState.properties.isCottage || localStorageState.properties.isRentalProperties;
                })

            }
            localStorage.setItem('savingsAndInvestments', JSON.stringify(localStorageState));

            dispatch(updateProperties(data))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }
}

export function updateProperties(data) {
    return {
        type: SET_PROPERTIES,
        data
    }
}

export function setCottageEquity(val) {
    setLocalStorageData({
                            type: 'setCottageEquity',
                            val
                        })
    return {
        type: SET_COTTAGE_EQUITY,
        val
    }
}

export function setRentalEquity(val) {
    setLocalStorageData({
                            type: 'setRentalEquity',
                            val
                        })
    return {
        type: SET_RENTAL_EQUITY,
        val
    }
}

export function setHomeEquity(val) {
    setLocalStorageData({
                            type: 'setHomeEquity',
                            val
                        })
    return {
        type: SET_HOME_EQUITY,
        val
    }
}

export function setInheritanceYou(val) {
    setLocalStorageData({
                            type: 'setInheritanceYou',
                            val
                        })
    return {
        type: SET_INHERITANCE_YOU,
        val
    }
}

export function setInheritanceSpouse(val) {
    setLocalStorageData({
                            type: 'setInheritanceSpouse',
                            val
                        })
    return {
        type: SET_INHERITANCE_SPOUSE,
        val
    }
}

export function setInheritanceAmountYou(val) {
    setLocalStorageData({
                            type: 'setInheritanceAmountYou',
                            val
                        })
    return {
        type: SET_INHERITANCE_AMOUNT_YOU,
        val
    }
}

export function setInheritanceAmountSpouse(val) {
    setLocalStorageData({
                            type: 'setInheritanceAmountSpouse',
                            val
                        })
    return {
        type: SET_INHERITANCE_AMOUNT_SPOUSE,
        val
    }
}

export function setInheritanceYearYou(val) {
    setLocalStorageData({
                            type: 'setInheritanceYearYou',
                            val
                        })
    return {
        type: SET_INHERITANCE_YEAR_YOU,
        val
    }
}

export function setInheritanceYearSpouse(val) {
    setLocalStorageData({
                            type: 'setInheritanceYearSpouse',
                            val
                        })
    return {
        type: SET_INHERITANCE_YEAR_SPOUSE,
        val
    }
}

export function thirdStepCompleted(val) {
    setLocalStorageData({
                            type: 'thirdStepCompleted',
                            val,
                        })

    return {
        type: SET_THIRD_STEP_COMPLETED,
        val,
    }
}