import React, { useState } from 'react';
import { Col, Row } from "react-flexbox-grid";
import classes from "../../../../AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { setHomeEquity } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import InputMask from "react-input-mask";


const SavingsAndInvestmentsPropertiesSum = ( props ) => {
    const [home, setHome] = useState(props.properties);

    const onChange = e => {
        const val = +e.target.value.replace(/\$|\s/g, '');
        const name = e.target.name;
        let homeUpdated = [...home];
        if (name === `${props.type}1` ) {
            homeUpdated[0] = val;
        }else {
            homeUpdated[1] = val;
        }
        setHome(homeUpdated)
        props.onChange(homeUpdated)
    };

    return (
        <React.Fragment>
            {
                props.properties &&
                props.properties.map((val, index) => {
                    return (
                        <Row
                            key={index}
                            className={classes.Row}
                        >
                            <Col xs={12} sm={6}>
                                <strong>{`${props.label} Equity Amount ${index+1}`}</strong>
                            </Col>
                            <Col xs={12} sm={6}>
                                <InputMask
                                    mask={'$9999999'}
                                    value={'$'+home[index] || '$0'}
                                    onChange={onChange}
                                    name= {`${props.type}${index+1}`}
                                    maskPlaceholder={null}
                                />
                            </Col>
                            <Col xs={12} className={classes.Separator}><hr/></Col>
                        </Row>
                    )
                })
            }
        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        setHomeEquity : val => dispatch(setHomeEquity(val))
    }
}

export default connect(null, mapDispatchToProps)(SavingsAndInvestmentsPropertiesSum);