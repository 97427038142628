import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from "react-flexbox-grid";
import classes from "../../../AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import Error from "../../../Error/Error";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import InputMask from "react-input-mask";


const SavingsAndInvestmentsTotalSum = ( props ) => {

    const [valueYou, setValueYou] = useState(props.totalYou || 0);
    const [valueSpouse, setValueSpouse] = useState(props.totalSpouse || 0);
    const [errorYou, setErrorYou] = useState(false);
    const [errorSpouse, setErrorSpouse] = useState(false);
    const initErrorMessage = 'Value required';
    const [errorYouMessage, setErrorYouMessage] = useState(initErrorMessage);
    const [errorSpouseMessage, setErrorSpouseMessage] = useState(initErrorMessage);

    const additionalClass = props.isSpouse ? 3 : 6;

    const validationYou = useCallback((val) => {
        if(props.youExist) {
            if(props.type === 'inheritance') {
                if(val < 1000){
                    setErrorYou(true);
                    setErrorYouMessage(`Must be between 1000 to ${props.max}`)
                } else {
                    setErrorYou(false)
                }
            } else {
                if(val > props.max){
                    setErrorYou(true);
                    setErrorYouMessage(`Must be between 0 to ${props.max}`)
                }else if(val<1) {
                    setErrorYou(true);
                    setErrorYouMessage(initErrorMessage)
                } else {
                    setErrorYou(false)
                }
            }
        }else {
            setErrorYou(false)
        }

    }, [props]);

    const validationSpouse = useCallback((val) => {
        if(props.spouseExist) {
            if(props.type === 'inheritance') {
                if(val < 1000){
                    setErrorSpouse(true);
                    setErrorSpouseMessage(`Must be between 1000 to ${props.max}`)
                } else {
                    setErrorSpouse(false)
                }
            }else {
                if(val > props.max){
                    setErrorSpouse(true);
                    setErrorSpouseMessage(`Must be between 0 to ${props.max}`)
                }else if(val<1) {
                    setErrorSpouse(true);
                    setErrorSpouseMessage(initErrorMessage)
                } else {
                    setErrorSpouse(false)
                }
            }
        }else {
            setErrorSpouse(false)
        }

    }, [props]);


    useEffect(()=> {
        validationYou(props.totalYou);
        validationSpouse(props.totalSpouse)
    }, [props, validationYou, validationSpouse]);

    const onChange = (e) => {
        const value = +e.currentTarget.value.replace(/\$|\s/g, '');

        if (e.currentTarget.name === `you${props.label}` ) {
            setValueYou(value);
            validationYou(value);
            props.setYou(value)
        }else {
            setValueSpouse(value);
            validationSpouse(value);
            props.setSpouse(value)
        }

    };

    return (

        <Row
            className={classes.Row}
        >
            <Col xs={12} sm={6}>
                <strong>{props.label}</strong>
            </Col>
            <Col xs={12} sm={additionalClass}>
                {
                    errorYou &&
                    <Error
                        additionClass={'errorTopPosition'}
                        message={errorYouMessage}
                    />
                }
                <InputMask
                    mask={'$9999999'}
                    value={'$' + valueYou || '$0'}
                    onChange={onChange}
                    name={`you${props.label}`}
                    maskPlaceholder={null}
                    disabled={!props.youExist}
                />
            </Col>
            {
                props.isSpouse &&
                <Col xs={12} sm={3}>
                    {
                        errorSpouse &&
                        <Error
                            additionClass={'errorTopPosition'}
                            message={errorSpouseMessage}
                        />
                    }
                    <InputMask
                        mask={'$9999999'}
                        value={'$' + valueSpouse || '$0'}
                        onChange={onChange}
                        name={`spouse${props.label}`}
                        maskPlaceholder={null}
                        disabled={!props.spouseExist}
                    />
                </Col>
            }

            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

SavingsAndInvestmentsTotalSum.propTypes ={
    isSpouse: PropTypes.bool,
    totalYou: PropTypes.number,
    totalSpouse: PropTypes.number,
    youExist: PropTypes.bool,
    spouseExist: PropTypes.bool,
    max: PropTypes.number,
    setYou: PropTypes.func.isRequired,
    setSpouse: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(SavingsAndInvestmentsTotalSum);