import React from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";

const AboutYouSumSave = () => {
    return (
        <Row className={classes.Row}>
            <Col xs={12}>
                <p className='lookingGood'>Looking good! <strong>Keep going</strong></p>
            </Col>
            {/*<Col xs={12} sm={6}>
                <p style={{color: 'rgb(2, 149, 219)'}}>Looking good!</p>
                <p style={{color: 'rgb(2, 149, 219)'}}><strong>Keep going</strong></p>
            </Col>
            <Col xs={12} sm={6}>
                 <button type='submit'>Save</button>
            </Col>*/}
            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

export default AboutYouSumSave;