import React from 'react';
import { connect } from "react-redux";
import {
    setWorkplaceSavingsContributeSpouse,
    setWorkplaceSavingsContributeYou,
} from "../../../../store/actions/savingsAndInvestments";
import SavingsAndInvestmentsTotalSum from "./SavingsAndInvestmentsTotalSum";

const SavingsAndInvestmentsWorkSavingsContributionSum = ( props ) => {

    return (
        <SavingsAndInvestmentsTotalSum
            totalYou = {props.totalYou}
            totalSpouse = {props.totalSpouse}
            youExist = {props.youExist}
            spouseExist = {props.spouseExist}
            setYou = {props.setYou}
            setSpouse = {props.setSpouse}
            label = 'Workplace Savings Contribution'
            max={999999}
        />
    );
};

function mapStateToProps(state) {
    return {
        youExist: state.savingsAndInvestments.you.workplaceSavings.exist,
        spouseExist: state.savingsAndInvestments.spouse.workplaceSavings.exist,
        totalYou: state.savingsAndInvestments.you.workplaceSavings.contributeMonthly,
        totalSpouse: state.savingsAndInvestments.spouse.workplaceSavings.contributeMonthly,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setYou: (val) => dispatch(setWorkplaceSavingsContributeYou(val)),
        setSpouse: (val) => dispatch(setWorkplaceSavingsContributeSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsWorkSavingsContributionSum)