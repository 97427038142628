import React  from 'react';
import { setCottageEquity } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import SavingsAndInvestmentsPropertiesSum from "./SavingsAndInvestmentsPropertiesSum";


const SavingsAndInvestmentsCottageSum = ( props ) => {

    const onChange = (val) => {
        props.setCottageEquity(val);
    };

    return (
        <SavingsAndInvestmentsPropertiesSum
            properties={props.cottages}
            type={'cottages'}
            label={'Cottage'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        cottages: state.savingsAndInvestments.properties.cottages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCottageEquity : val => dispatch(setCottageEquity(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsCottageSum);