import React  from 'react';
import { setRentalEquity } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import SavingsAndInvestmentsPropertiesSum from "./SavingsAndInvestmentsPropertiesSum";


const SavingsAndInvestmentsRentalSum = ( props ) => {

    const onChange = (val) => {
        props.setRentalEquity(val);
    };

    return (
        <SavingsAndInvestmentsPropertiesSum
            properties={props.rentalProperties}
            type={'rental'}
            label={'Rental'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        rentalProperties: state.savingsAndInvestments.properties.rentalProperties
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRentalEquity : val => dispatch(setRentalEquity(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsRentalSum);