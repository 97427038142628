import React from 'react';
import { connect } from "react-redux";
import {
    setInheritanceAmountSpouse,
    setInheritanceAmountYou,
} from "../../../../store/actions/savingsAndInvestments";
import SavingsAndInvestmentsTotalSum from "./SavingsAndInvestmentsTotalSum";

const SavingsAndInvestmentsInheritanceSum = ( props ) => {

    return (
        <SavingsAndInvestmentsTotalSum
            totalYou = {props.totalYou}
            totalSpouse = {props.totalSpouse}
            youExist = {props.youExist}
            spouseExist = {props.spouseExist}
            setYou = {props.setYou}
            setSpouse = {props.setSpouse}
            label = 'Inheritance'
            max={9999999}
            type={'inheritance'}
        />
    );
};

function mapStateToProps(state) {
    return {
        youExist: state.savingsAndInvestments.you.inheritance.exist,
        spouseExist: state.savingsAndInvestments.spouse.inheritance.exist,
        totalYou: state.savingsAndInvestments.you.inheritance.total,
        totalSpouse: state.savingsAndInvestments.spouse.inheritance.total,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setYou: (val) => dispatch(setInheritanceAmountYou(val)),
        setSpouse: (val) => dispatch(setInheritanceAmountSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsInheritanceSum)