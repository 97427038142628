import React from 'react';
import classes from './SidebarContent.module.scss'
import man from '../../assets/images/man.png';
import woman from '../../assets/images/women.png';
import { connect } from "react-redux";

const SidebarContent = ( props ) => {
    const activeClass = val => {
        if(props.active === val){
            return classes.active
        }
        return ''
    };

    const stepLink = (val, title, link) => {
        let isFinished;

        switch(val) {
            case 'step1':
                isFinished = props.firstStepCompleted;
                break;
            case 'step2':
                isFinished = props.secondStepCompleted;
                break;
            case 'step3':
                isFinished = props.thirdStepCompleted;
                break;
            default :
                isFinished = false
        }

        if(isFinished){
            return <div className={classes.finished} dangerouslySetInnerHTML={{ __html: title }}/>
            //return <Link to={link} dangerouslySetInnerHTML={{ __html: title }} />
        }
        return <div dangerouslySetInnerHTML={{ __html: title }}/>
    };
    return (
        <React.Fragment>
            <div className={classes.SidebarHeader}>
                <div className={classes.SidebarImg}>
                    {
                        props.iAm === 'Male'
                        ?  <img src = {man} alt = "Male"  width="69" height='69'/>
                        :  <img src = {woman} alt = "Female"  width="69" height='69'/>
                    }
                </div>
                <strong>You</strong>
            </div>

            <ul className={classes.Steps}>
                <li className={activeClass('step1')} >
                    {stepLink('step1','ABOUT YOU', '/about-you')}
                </li>
                <li className={activeClass('step2')} >
                    {stepLink('step2','INVESTOR PROFILE', '/short-term-losses')}
                </li>
                <li className={activeClass('step3')}>
                    {stepLink('step3','Savings & <br/>Investments', '/savings-investments')}
                </li>
                <li className={activeClass('step4')}>
                    {stepLink('step3','INCOME SOURCES <br/>SUMMARY', '/income-source-summary')}
                </li>
            </ul>

        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        iAm: state.aboutYou.iAm,
        firstStepCompleted: state.aboutYou.firstStepCompleted,
        secondStepCompleted: state.investorProfile.secondStepCompleted,
        thirdStepCompleted: state.savingsAndInvestments.thirdStepCompleted
    }
}

export default connect(mapStateToProps, null)(SidebarContent);