import React  from 'react';
import { setHomeEquity } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import SavingsAndInvestmentsPropertiesSum from "./SavingsAndInvestmentsPropertiesSum";


const SavingsAndInvestmentsHomeSum = ( props ) => {

    const onChange = (val) => {
        props.setHomeEquity(val);
    };

    return (
        <SavingsAndInvestmentsPropertiesSum
            properties={props.home}
            type={'home'}
            label={'Home'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        home: state.savingsAndInvestments.properties.home
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setHomeEquity : val => dispatch(setHomeEquity(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsHomeSum);