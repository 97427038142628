import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    setInheritanceYearSpouse, setInheritanceYearYou,
} from "../../../../store/actions/savingsAndInvestments";
import { Col, Row } from "react-flexbox-grid";
import classes from "../../../AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import Error from "../../../Error/Error";
import InputMask from "react-input-mask";

const SavingsAndInvestmentsInheritanceYearSum = ( props ) => {

    const additionalClass = props.isSpouse ? 3 : 6;

    const [valueYou, setValueYou] = useState(props.totalYou || 0);
    const [valueSpouse, setValueSpouse] = useState(props.totalSpouse || 0);
    const [errorYou, setErrorYou] = useState(false);
    const [errorSpouse, setErrorSpouse] = useState(false);
    const initErrorMessage = 'Value required';
    const [errorYouMessage, setErrorYouMessage] = useState(initErrorMessage);
    const [errorSpouseMessage, setErrorSpouseMessage] = useState(initErrorMessage);

    const validationYou = useCallback((val) => {
        const currentYear = new Date().getFullYear();
        if(val <= currentYear){
            setErrorYou(true);
            setErrorYouMessage( 'Value must be next year or later')
        }else if(val>2080){
            setErrorYou(true);
            setErrorYouMessage( 'Value can not be greater than 2080')
        }else {
            setErrorYou(false)
        }
    }, []);

    const validationSpouse = useCallback((val) => {
        const currentYear = new Date().getFullYear();
        if(val <= currentYear){
            setErrorSpouse(true);
            setErrorSpouseMessage( 'Value must be next year or later')
        }else if(val>2080){
            setErrorSpouse(true);
            setErrorSpouseMessage( 'Value can not be greater than 2080')
        }else {
            setErrorSpouse(false)
        }
    }, []);

    const onChange = (e) => {
        const value = +e.currentTarget.value.replace(/\$|\s/g, '');

        if (e.currentTarget.name === 'you' ) {
            setValueYou(value);
            validationYou(value);
            props.setYou(value)
        }else {
            setValueSpouse(value);
            validationSpouse(value);
            props.setSpouse(value)
        }

    };

    useEffect(()=> {
        validationYou(props.totalYou);
        validationSpouse(props.totalSpouse)
    }, [props, validationYou, validationSpouse]);

    return (

        <Row
            className={classes.Row}
        >
            <Col xs={12} sm={6}>
                <strong>Inheritance Year</strong>
            </Col>
            <Col xs={12} sm={additionalClass}>
                {
                    errorYou &&
                    <Error
                        additionClass={'errorTopPosition'}
                        message={errorYouMessage}
                    />
                }
                <InputMask
                    //mask={'9999999'}
                    value={valueYou || '0'}
                    onChange={onChange}
                    name='you'
                    maskPlaceholder={null}
                    disabled={!props.youExist}
                />
            </Col>
            {
                props.isSpouse &&
                <Col xs={12} sm={3}>
                    {
                        errorSpouse &&
                        <Error
                            additionClass={'errorTopPosition'}
                            message={errorSpouseMessage}
                        />
                    }
                    <InputMask
                        //mask={'9999999'}
                        value={valueSpouse || '0'}
                        onChange={onChange}
                        name='spouse'
                        maskPlaceholder={null}
                        disabled={!props.spouseExist}
                    />
                </Col>
            }

            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        youExist: state.savingsAndInvestments.you.inheritance.exist,
        spouseExist: state.savingsAndInvestments.spouse.inheritance.exist,
        totalYou: state.savingsAndInvestments.you.inheritance.inheritanceYear,
        totalSpouse: state.savingsAndInvestments.spouse.inheritance.inheritanceYear,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setYou: (val) => dispatch(setInheritanceYearYou(val)),
        setSpouse: (val) => dispatch(setInheritanceYearSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsInheritanceYearSum)