import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { thirdStepCompleted } from "../../../store/actions/savingsAndInvestments";
import classes from "../../../components/AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Grid } from "react-flexbox-grid";
import AboutYouSumHeader from "../../../components/AboutYouSummary/AboutYouSumHeader";
import SavingsAndInvestmentsRrspSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsRrspSum";
import AboutYouSumSave from "../../../components/AboutYouSummary/AboutYouSumSave";
import Navigation from "../../../components/Navigation/Navigation";
import SavingsAndInvestmentsRrspContributionSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsRrspContributionSum";
import SavingsAndInvestmentsWorkSavingsSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsWorkSavingsSum";
import SavingsAndInvestmentsWorkSavingsContributionSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsWorkSavingsContributionSum";
import SavingsAndInvestmentsLockedInSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsLockedInSum";
import SavingsAndInvestmentsTfsaSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsTfsaSum";
import SavingsAndInvestmentsTfsaContributionSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsTfsaContributionSum";
import SavingsAndInvestmentsNonRegisteredSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsNonRegisteredSum";
import SavingsAndInvestmentsNonRegisteredContributionSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsNonRegisteredContributionSum";
import SavingsAndInvestmentsInheritanceSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsInheritanceSum";
import SavingsAndInvestmentsInheritanceYearSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/SavingsAndInvestmentsInheritanceYearSum";
import SavingsAndInvestmentsHomeSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/properties/SavingsAndInvestmentsHomeSum";
import SavingsAndInvestmentsCottagesSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/properties/SavingsAndInvestmentsCottagesSum";
import SavingsAndInvestmentsRentalSum
    from "../../../components/ContentRow/Step3/SavingsAndInvestmentsSum/properties/SavingsAndInvestmentsRentalSum";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const SavingsAndInvestmentsSummary = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR SAVINGS & INVESTMENTS SUMMARY',
    };

    useEffect(() => {
        props.thirdStepCompleted(true)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={100}
            active={'step3'}
        >
            <Helmet>
                <title>{titles.layoutTitle}</title>
            </Helmet>

            <form className={classes.AboutYouSummary}>
                <Grid>
                    <AboutYouSumHeader/>

                    {
                        (props.savingsAndInvestmentsYou.rrsp.exist || props.savingsAndInvestmentsSpouse.rrsp.exist) &&
                            <React.Fragment>
                                <SavingsAndInvestmentsRrspSum/>
                                <SavingsAndInvestmentsRrspContributionSum/>
                            </React.Fragment>
                    }


                    {
                        (props.savingsAndInvestmentsYou.workplaceSavings.exist || props.savingsAndInvestmentsSpouse.workplaceSavings.exist) &&
                        <React.Fragment>
                            <SavingsAndInvestmentsWorkSavingsSum/>
                            <SavingsAndInvestmentsWorkSavingsContributionSum/>
                        </React.Fragment>
                    }

                    {
                        (props.savingsAndInvestmentsYou.lockedIn.exist || props.savingsAndInvestmentsSpouse.lockedIn.exist) &&
                        <React.Fragment>
                            <SavingsAndInvestmentsLockedInSum/>
                        </React.Fragment>
                    }

                    {
                        (props.savingsAndInvestmentsYou.tfsa.exist || props.savingsAndInvestmentsSpouse.tfsa.exist) &&
                        <React.Fragment>
                            <SavingsAndInvestmentsTfsaSum/>
                            <SavingsAndInvestmentsTfsaContributionSum/>
                        </React.Fragment>
                    }

                    {
                        (props.savingsAndInvestmentsYou.nonRegistered.exist || props.savingsAndInvestmentsSpouse.nonRegistered.exist) &&
                        <React.Fragment>
                            <SavingsAndInvestmentsNonRegisteredSum/>
                            <SavingsAndInvestmentsNonRegisteredContributionSum/>
                        </React.Fragment>
                    }

                    <SavingsAndInvestmentsHomeSum/>

                    {
                        props.savingsAndInvestmentsProperties.isCottage &&
                        <React.Fragment>
                            <SavingsAndInvestmentsCottagesSum/>
                        </React.Fragment>
                    }

                    {
                        props.savingsAndInvestmentsProperties.isRentalProperties &&
                        <React.Fragment>
                            <SavingsAndInvestmentsRentalSum/>
                        </React.Fragment>
                    }

                    {
                        (props.savingsAndInvestmentsYou.inheritance.exist || props.savingsAndInvestmentsSpouse.inheritance.exist) &&
                        <React.Fragment>
                            <SavingsAndInvestmentsInheritanceSum/>
                            <SavingsAndInvestmentsInheritanceYearSum/>
                        </React.Fragment>
                    }

                    <AboutYouSumSave/>
                </Grid>
            </form>

            <Navigation
                isCompleted={true}
                next={retirementCalcLinks.sourceOfIncome}
            />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        savingsAndInvestmentsYou: state.savingsAndInvestments.you,
        savingsAndInvestmentsSpouse: state.savingsAndInvestments.spouse,
        savingsAndInvestmentsProperties: state.savingsAndInvestments.properties,
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsSummary);