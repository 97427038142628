import React from 'react';
import classes from './SimpleButton.module.scss'
import { useHistory } from "react-router-dom";
import './SimpleButton.scss'

const SimpleButton = ( {title, type=''} ) => {
    const cls = [classes.Btn]

    const history = useHistory();

    if  (type) {
        cls.push(type)
    }

    const button = () => {
        if  (type === 'btn back') {
            return <button
                        onClick={() => history.goBack()}
                        className={cls.join(' ')}
                    >
                        {title}
                    </button>
        }
        return <button
                    className={cls.join(' ')}
                >
                    {title}
                </button>
    }

    return (
        <React.Fragment>
            {button()}
        </React.Fragment>
    )
};

export default SimpleButton;