import React from 'react';
import { connect } from "react-redux";
import {
    setNonRegisteredTotalSpouse,
    setNonRegisteredTotalYou,
} from "../../../../store/actions/savingsAndInvestments";
import SavingsAndInvestmentsTotalSum from "./SavingsAndInvestmentsTotalSum";

const SavingsAndInvestmentsNonRegisteredSum = ( props ) => {

    return (
        <SavingsAndInvestmentsTotalSum
            totalYou = {props.totalYou}
            totalSpouse = {props.totalSpouse}
            youExist = {props.youExist}
            spouseExist = {props.spouseExist}
            setYou = {props.setYou}
            setSpouse = {props.setSpouse}
            label = 'Non-registered'
            max={5000000}
        />
    );
};

function mapStateToProps(state) {
    return {
        youExist: state.savingsAndInvestments.you.nonRegistered.exist,
        spouseExist: state.savingsAndInvestments.spouse.nonRegistered.exist,
        totalYou: state.savingsAndInvestments.you.nonRegistered.total,
        totalSpouse: state.savingsAndInvestments.spouse.nonRegistered.total,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setYou: (val) => dispatch(setNonRegisteredTotalYou(val)),
        setSpouse: (val) => dispatch(setNonRegisteredTotalSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsNonRegisteredSum)