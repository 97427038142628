import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import classes from './Navigation.module.scss'
import SimpleButton from "../UI/SimpleButton/SimpleButton";

const Navigation = ( props ) => {

    const cls = [];
    if (props.type === 'start') {
        cls.push(classes.formNavigation)
    }

    if(props.additionalClass) {
        cls.push(props.additionalClass)
    }

    return (
        <div className={cls.join(' ')}>
            <nav className={classes.mainNav}>
                <ul>
                    <li>
                        <SimpleButton title={'Back'}  type={'btn back'}/>
                    </li>
                    {
                        props.next &&
                        <li>
                        {
                            props.isCompleted
                                ?
                                <NavLink to={props.next}>
                                    <SimpleButton title={'Continue'} type={'btn'}/>
                                 </NavLink>
                                    :
                                    <span style={{opacity: 0.7}} className={classes.disabled}>Continue
                            </span>
                        }
                    </li>
                    }
                </ul>
            </nav>
        </div>

    )
};

export default withRouter(Navigation);