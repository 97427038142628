import {
    SET_I_AM_VAL,
    SET_I_AM_YEARS_OLD,
    FETCH_RADIO_ERROR,
    UPDATE_MY_FAMILY_DATA,
    SET_SEX_SPOUSE, SET_SPOUSE_YEARS_OLD,
    SET_CHILD_YEARS_OLD,
    SET_CHILDREN,
    SET_DEPENDANTS,
    SET_DEPENDANT_YEARS_OLD,
    IS_COMPLETED,
    SET_RETIRE_AGE,
    SET_YEARS_INCOME,
    SET_RENT_OR_OWNER,
    SET_POSTAL_CODE,
    SET_ANTICIPATED_INCOME,
    SET_INFLATION,
    SET_SPOUSE_RETIRE_AGE,
    SET_ABOUT_YOU_SUM_RETIRE_AGE_ERROR,
    SET_ABOUT_YOU_SUM_COMPLETED,
    RESET_FIRST_STEP_STATE
} from "./actionTypes";

import AboutYouInitState from '../initStates/aboutYouInitState'

let localStorageState = AboutYouInitState

export function setRadioValue(event) {
    return async dispatch => {
        try {

            setLocalStorageData({
                type: 'setRadio',
                event
            })

            dispatch(setRadio(event))

            dispatch(validationHandler())
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }
}

export function setRadio(event) {
    switch (event.target.name) {
        case 'iAmGender':
            return {
                type: SET_I_AM_VAL,
                val: event.target.value
            }
        case 'sexSpouse':
            return {
                type: SET_SEX_SPOUSE,
                val: event.target.value
            }
        case 'children':
            return {
                type: SET_CHILDREN,
                val: event.target.value
            }
        case 'dependants':
            return {
                type: SET_DEPENDANTS,
                val: event.target.value
            }
        default :
            return true
    }

}

export function setIAmYearsOldHandler(val) {
    return async dispatch => {
        try {
            setLocalStorageData({
                                    type: 'setIAmYearsOld',
                                    years: val
                                })

            dispatch(setIAmYearsOld(val))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }
}

export function setIAmYearsOld(val) {
    return {
        type: SET_I_AM_YEARS_OLD,
        val
    }
}

export function setSpouseYearsOldHandler(val) {
    return async dispatch => {
        try {
            setLocalStorageData({
                                    type: 'setSpouseYearsOld',
                                    years: val
                                })

            dispatch(setSpouseYearsOld(val))
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }
}

export function setSpouseYearsOld(val) {
    return {
        type: SET_SPOUSE_YEARS_OLD,
        val
    }
}

export function updateMyFamilyData(checkedItems) {
    return async dispatch => {
        try {
            const aboutYou = localStorage.getItem('aboutYou')

            const data = {}
            if(aboutYou) {
                localStorageState = JSON.parse(aboutYou)

                data.isCompleted = false
                localStorageState.isCompleted = false

                checkedItems.forEach((checked, name)=>{
                    if (name === 'justMe') {
                        data.me = checked
                        localStorageState.myFamily.me = checked
                    }
                    if (name === 'spouse') {
                        data.spouse = checked
                        localStorageState.myFamily.spouseData.isSpouse = checked
                    }
                    if (name === 'isChildren') {
                        data.children = checked
                        localStorageState.myFamily.childrenData.isChildren = checked
                    }
                    if (name === 'isDependants') {
                        data.dependants = checked
                        localStorageState.myFamily.dependantsData.isDependants = checked
                    }
                })

                if(data.me && !data.spouse && ! data.children && !data.dependants) {
                    data.isCompleted = true
                    localStorageState.isCompleted = true
                }
            }
            localStorage.setItem('aboutYou', JSON.stringify(localStorageState));

            dispatch(updateMyFamily(data))
            dispatch(validationHandler())
        } catch (e) {
            dispatch(fetchRadioError(e))
        }
    }

}

export function updateMyFamily(data) {
    return {
        type: UPDATE_MY_FAMILY_DATA,
        data
    }
}

export function setChildYearsOld(key, val){

    setLocalStorageData({
                            type: 'setChildYearsOld',
                            yearsData: [key, val]
                        })

    return {
        type: SET_CHILD_YEARS_OLD,
        key,
        val
    }
}

export function setDependantYearsOld(key, val){

    setLocalStorageData({
                            type: 'setDependantYearsOld',
                            yearsData: [key, val]
                        })

    return {
        type: SET_DEPENDANT_YEARS_OLD,
        key,
        val
    }
}

function setLocalStorageData(params) {

    const aboutYou = localStorage.getItem('aboutYou')

    //console.log( aboutYou );


    localStorageState = aboutYou ? JSON.parse(aboutYou) : localStorageState


    switch (params.type ) {
            case 'setRadio':
                const event = params.event
                radioButtonsHandlerLS(event, localStorageState)
                break;
            case 'setIAmYearsOld':
                localStorageState.iAmYearsOld = params.years
                break;
            case 'setSpouseYearsOld':
                localStorageState.myFamily.spouseData.yearsOld = params.years
                break;
            case 'setChildYearsOld':
                localStorageState.myFamily.childrenData.children.years[params.yearsData[0]] = params.yearsData[1]
                break;
            case 'setDependantYearsOld':
                localStorageState.myFamily.dependantsData.dependants.years[params.yearsData[0]] = params.yearsData[1]
                break;
            case 'setRetireAge':
                localStorageState.retireAge = params.years
                break;
            case 'setSpouseRetireAge':
                localStorageState.spouseRetireAge = params.years
                break;
            case 'setYearsIncome':
                localStorageState.yearsIncome = params.years
                localStorageState.averageLife = params.averageLife
                break;
            case 'setRentOrOwner':
                localStorageState.rentOrOwn = params.val
                break;
            case 'setPostalCode':
                localStorageState.postalCode = params.val
                break;
            case 'setAnticipatedIncome':
                localStorageState.anticipatedIncome = params.val
                break;
            case 'setInflation':
                localStorageState.inflation = params.val
                break;
            case 'setAboutYouSumCompleted':
                localStorageState.firstStepCompleted = !params.val
                break;
            case 'resetFirstStep':
                localStorageState = AboutYouInitState
                break;
            default:
                return true
        }

    localStorage.setItem('aboutYou', JSON.stringify(localStorageState));
}

function radioButtonsHandlerLS(event={}, localStorageState={}){
    if (!isEmpty(event) && !isEmpty(localStorageState) ){
        switch (event.target.name) {
            case 'iAmGender':
                localStorageState.iAm = event.target.value
                break
            case 'sexSpouse':
                localStorageState.myFamily.spouseData.gender = event.target.value
                break
            case 'children':
                localStorageState.myFamily.childrenData.children.count = event.target.value
                break
            case 'dependants':
                localStorageState.myFamily.dependantsData.dependants.count = event.target.value
                break
            default:
                return localStorageState
        }
    }
}

function isEmpty(obj) {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function fetchRadioError(e) {
    return{
        type: FETCH_RADIO_ERROR,
        error: e
    }
}

export function validationHandler() {
    return {
        type: IS_COMPLETED,
    }
}

export function setRetireAge(val) {
    setLocalStorageData({
                            type: 'setRetireAge',
                            years: val
                        })

    return {
        type: SET_RETIRE_AGE,
        val
    }
}

export function setSpouseRetireAge(val) {
    setLocalStorageData({
                            type: 'setSpouseRetireAge',
                            years: val
                        })

    return {
        type: SET_SPOUSE_RETIRE_AGE,
        val
    }
}

export function setYearsIncome(yearsVal, averageLife) {
    setLocalStorageData({
                            type: 'setYearsIncome',
                            years: yearsVal,
                            averageLife,
                        })

    return {
        type: SET_YEARS_INCOME,
        yearsVal,
        averageLife
    }
}

export function changeRentOrOwner(val) {
    console.log( val );
    setLocalStorageData({
                            type: 'setRentOrOwner',
                            val,
                        })

    return {
        type: SET_RENT_OR_OWNER,
        val
    }
}

export function changePostalCode(val) {
    setLocalStorageData({
                            type: 'setPostalCode',
                            val,
                        })

    return {
        type: SET_POSTAL_CODE,
        val
    }
}

export function setAnticipatedIncome(val) {
    setLocalStorageData({
                            type: 'setAnticipatedIncome',
                            val,
                        })

    return {
        type: SET_ANTICIPATED_INCOME,
        val
    }
}

export function setInflation(val) {
    setLocalStorageData({
                            type: 'setInflation',
                            val,
                        })

    return {
        type: SET_INFLATION,
        val
    }
}

export function setAboutYouSumRetireAgeError(val) {
    return {
        type: SET_ABOUT_YOU_SUM_RETIRE_AGE_ERROR,
        val
    }
}

export function setAboutYouSumCompleted(val) {
    setLocalStorageData({
                            type: 'setAboutYouSumCompleted',
                            val,
                        })
    return {
        type: SET_ABOUT_YOU_SUM_COMPLETED,
        val
    }
}

export function resetFirstStep() {
    setLocalStorageData({
                            type: 'resetFirstStep',
                        })
    return {
        type: RESET_FIRST_STEP_STATE
    }
}