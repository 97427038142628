import React from 'react';
import { connect } from "react-redux";
import {
    setLockedInTotalSpouse,
    setLockedInTotalYou,
} from "../../../../store/actions/savingsAndInvestments";
import SavingsAndInvestmentsTotalSum from "./SavingsAndInvestmentsTotalSum";

const SavingsAndInvestmentsLockedInSum = ( props ) => {

    return (
        <SavingsAndInvestmentsTotalSum
            totalYou = {props.totalYou}
            totalSpouse = {props.totalSpouse}
            youExist = {props.youExist}
            spouseExist = {props.spouseExist}
            setYou = {props.setYou}
            setSpouse = {props.setSpouse}
            label = 'Locked-in'
            max={5000000}
        />
    );
};

function mapStateToProps(state) {
    return {
        youExist: state.savingsAndInvestments.you.lockedIn.exist,
        spouseExist: state.savingsAndInvestments.spouse.lockedIn.exist,
        totalYou: state.savingsAndInvestments.you.lockedIn.total,
        totalSpouse: state.savingsAndInvestments.spouse.lockedIn.total,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setYou: (val) => dispatch(setLockedInTotalYou(val)),
        setSpouse: (val) => dispatch(setLockedInTotalSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsLockedInSum)