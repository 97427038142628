import React from 'react';
import classes from "./Error.module.scss"

const Error = ( {message, style={}, additionClass= ''} ) => {

    const cl = [classes.ErrorSection, additionClass];
    return (
        <div className={cl.join(' ')} style={style}> {message}</div>
    )
};

export default Error;