import React from 'react';
import SavingsAndInvestmentsTotalSum from "./SavingsAndInvestmentsTotalSum";
import {
    setRrspContributeSpouse,
    setRrspContributeYou,
} from "../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const SavingsAndInvestmentsRrspContributionSum = ( props ) => {

    return (
        <SavingsAndInvestmentsTotalSum
            totalYou = {props.totalYou}
            totalSpouse = {props.totalSpouse}
            youExist = {props.youExist}
            spouseExist = {props.spouseExist}
            setYou = {props.setYou}
            setSpouse = {props.setSpouse}
            label = 'RRSP Contribution'
            max={999999}
        />
    );
};

function mapStateToProps(state) {
    return {
        youExist: state.savingsAndInvestments.you.rrsp.exist,
        spouseExist: state.savingsAndInvestments.spouse.rrsp.exist,
        totalYou: state.savingsAndInvestments.you.rrsp.contributeMonthly,
        totalSpouse: state.savingsAndInvestments.spouse.rrsp.contributeMonthly,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setYou: (val) => dispatch(setRrspContributeYou(val)),
        setSpouse: (val) => dispatch(setRrspContributeSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsAndInvestmentsRrspContributionSum);