import React from 'react';
import classes from './ProgressBar.module.scss';
import PropTypes from "prop-types";

const ProgressBar = ( {progress, isFinished} ) => {
    const progressVal = isFinished ? 100 : progress;
    return (
        <div className={classes.ProgressBar}>
            <div className={classes.myProgress}>
              <div className={classes.myBar} style={{width: progressVal + '%'}}></div>
            </div>
            {
                isFinished
                ? <div className={classes.numWrapFinished}>
                    <span></span>
                </div>
                    : <div className={classes.numWrap}>
                        <span></span>
                    </div>
            }
        </div>
    )
};

ProgressBar.propTypes = {
    type: PropTypes.number,
}

export default ProgressBar;