import React from 'react';
import classes from "./AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Row } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const AboutYouSumHeader = ( props ) => {

    const additionalClass = props.isSpouse ? 3 : 6

    return (
        <Row className={classes.Row} style={{textAlign: 'center'}}>
            <Col xs={12} sm={6}/>
            <Col xs={6} sm={additionalClass}>
                <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{fontSize: 60, color:'rgb(57, 103, 177)'}}
                />
                <span style={{display: 'block'}}>You</span>
            </Col>
            {
                props.isSpouse &&
                <Col xs={6} sm={3}>
                            <FontAwesomeIcon
                                icon={faUserCircle}
                                style={{fontSize: 60, color: 'rgb(57, 103, 177, .5)'}}
                            />
                           <span style={{display: 'block'}}>Spouse</span>
                        </Col>
            }

            <Col xs={12} className={classes.Separator}><hr/></Col>
        </Row>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

export default connect(mapStateToProps, null)(AboutYouSumHeader);