import React from "react";
import classes from './Layout.module.scss'
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

const Layout = ( props ) => {

    return(
        <React.Fragment>
                <div className={classes.FormLayout}>
                    <div className={classes.Sidebar}>
                        <SidebarContent
                            active={props.active}
                        />
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.ProgressWrap}>
                            <div className={classes.numWrapFinished}>
                                <span></span>
                            </div>
                            <ProgressBar number="1" progress={props.progressStep1} isFinished={props.firstStepCompleted}/>
                            <ProgressBar number="2" progress={props.progressStep2} isFinished={props.secondStepCompleted}/>
                            <ProgressBar number="3" progress={props.progressStep3} isFinished={props.thirdStepCompleted}/>
                            <ProgressBar
                                number="4"
                                progress={props.progressStep4}
                                isFinished={props.fourthStepCompleted}
                            />
                        </div>
                        <div className={classes.HeaderSection}>
                            <h1>{props.title}</h1>
                        </div>
                        {props.children}
                    </div>
                </div>
            </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        firstStepCompleted: state.aboutYou.firstStepCompleted,
        secondStepCompleted: state.investorProfile.secondStepCompleted,
        thirdStepCompleted: state.savingsAndInvestments.thirdStepCompleted,
        fourthStepCompleted: state.sourceOfIncome.fourthStepCompleted,
    }
}

export default withRouter(connect(mapStateToProps)(Layout));